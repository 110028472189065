import Vue from 'vue';
import TYPES from '@/types';

// Domain
import {
  OptionEntity,
} from '@/modules/my-investment/catalogs/allianz/option/domain/entities/option-entity';
import Translator from '@/modules/shared/domain/i18n/translator';
import {
  QuestionEntity,
} from '@/modules/my-investment/catalogs/allianz/question/domain/entities/question-entity';
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import {
  TemplateQuestionnaireEntity,
} from '@/modules/my-investment/catalogs/allianz/template-questionnaire/domain/entities/template-questionnaire-entity';
import { Values } from '@/modules/shared/domain/i18n/types';
import Inject from '@/modules/shared/domain/di/inject';

export default class InvestorProfileLevelExperienceViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.contract-savings.profiling.investment-profile';

  private readonly view!: Vue;

  is_loading = true;

  form_valid = false;

  etfs_items: Array<OptionEntity> = [];

  actions_items: Array<OptionEntity> = [];

  debt_instruments_items: Array<OptionEntity> = [];

  derivatives_items: Array<OptionEntity> = [];

  investment_currencies_items: Array<OptionEntity> = [];

  investment_companies_items: Array<OptionEntity> = [];

  investments_in_the_last_year_items: Array<OptionEntity> = [];

  income_in_the_last_years_items: Array<OptionEntity> = [];

  object_format = {
    llave: '',
    valor: '',
    descripcion: '',
  }

  onboarding_step: OnBoardingStepEntity = {
    id: '',
    current_step: '',
    investment_provider_id: '',
    payload: {
      etfs: this.object_format,
      actions: this.object_format,
      derivatives: this.object_format,
      deb_instruments: this.object_format,
      investment_companies: this.object_format,
      investment_currencies: this.object_format,
      investor_type_moderate: this.object_format,
      investor_type_selected: this.object_format,
      income_in_the_last_years: this.object_format,
      investor_type_aggressive: this.object_format,
      investor_type_conservative: this.object_format,
      investments_in_the_last_year: this.object_format,
    },
  };

  step_name = 'InvestorProfileLevelExperience';

  public constructor(view: Vue) {
    this.view = view;
  }

  get can_continue() {
    return this.form_valid && this.checkAlmostOneOptionSelected() && !this.is_loading;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  checkAlmostOneOptionSelected = () => (
    (
      (
        this.onboarding_step.payload.actions.descripcion === 'Si'
        || this.onboarding_step.payload.deb_instruments.descripcion === 'Si'
        || this.onboarding_step.payload.derivatives.descripcion === 'Si'
        || this.onboarding_step.payload.investment_companies.descripcion === 'Si'
        || this.onboarding_step.payload.etfs.descripcion === 'Si'
        || this.onboarding_step.payload.investment_currencies.descripcion === 'Si'
      ) || this.onboarding_step.payload.not_financial_knowledge
    ) && (
      (
        this.onboarding_step.payload.investments_in_the_last_year.descripcion === 'Si'
        || this.onboarding_step.payload.income_in_the_last_years.descripcion === 'Si'
      )
      || this.onboarding_step.payload.not_sophisticated_investor
    )
  );

  clearOptionNotFinancialKnowledge = () => {
    this.onboarding_step.payload.not_financial_knowledge = false;
  }

  clearOptionNotSophisticatedInvestor = () => {
    this.onboarding_step.payload.not_sophisticated_investor = false;
  }

  setFinancialExperienceDefaultOptions = () => {
    this.onboarding_step.payload.investment_companies = { ...this.investment_companies_items[1] };
    this.onboarding_step.payload.deb_instruments = { ...this.debt_instruments_items[1] };
    this.onboarding_step.payload.actions = { ...this.actions_items[1] };
    this.onboarding_step.payload.derivatives = { ...this.derivatives_items[1] };
    this.onboarding_step.payload.etfs = { ...this.etfs_items[1] };
    this.onboarding_step.payload.investment_currencies = { ...this.investment_currencies_items[1] };
  }

  setSophisticatedInvestorDefaultOptions = () => {
    this.onboarding_step.payload.investments_in_the_last_year = {
      ...this.investments_in_the_last_year_items[1],
    };
    this.onboarding_step.payload.income_in_the_last_years = {
      ...this.income_in_the_last_years_items[1],
    };
  }

  onNotFinancialKnowledgeChanged = (not_financial_knowledge: boolean) => {
    if (not_financial_knowledge) {
      this.setFinancialExperienceDefaultOptions();
    }
  }

  onNotSophisticatedInvestorChanged = (not_sophisticated_investor: boolean) => {
    if (not_sophisticated_investor) {
      this.setSophisticatedInvestorDefaultOptions();
    }
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = () => {
    this.onboarding_step.payload.internal_step = this.step_name;
    this.view.$emit('nextStep', this.onboarding_step);
  }

  loadOptions = (questions: Array<QuestionEntity>) => {
    if (questions.length) {
      this.investment_companies_items = questions[0].opciones;
      this.debt_instruments_items = questions[1].opciones;
      this.actions_items = questions[2].opciones;
      this.derivatives_items = questions[3].opciones;
      this.etfs_items = questions[4].opciones;
      this.investment_currencies_items = questions[5].opciones;
    }
  }

  loadSophisticatedOptions = (questions: Array<QuestionEntity>) => {
    this.investments_in_the_last_year_items = questions[0].opciones;
    this.income_in_the_last_years_items = questions[1].opciones;
  }

  setInputsDataFromStep = (onboarding_step: OnBoardingStepEntity) => {
    this.onboarding_step = { ...onboarding_step };
    if (!onboarding_step.payload.investments_in_the_last_year.descripcion) {
      this.setFinancialExperienceDefaultOptions();
      this.setSophisticatedInvestorDefaultOptions();
    }
  }

  chargeOnLoadFinished = (
    load_finished: boolean,
    template: Array<TemplateQuestionnaireEntity>,
    onboarding_step: OnBoardingStepEntity,
  ) => {
    if (load_finished) {
      const questionnaire = template[0];
      const question_entities = questionnaire.secciones[1].preguntas;
      const sophisticated_entities = questionnaire.secciones[2].preguntas;
      this.loadOptions(question_entities);
      this.loadSophisticatedOptions(sophisticated_entities);
      this.setInputsDataFromStep(onboarding_step);
    }
  }

  initialize = async () => {
    this.is_loading = false;
  }
}
